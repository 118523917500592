import * as actionTypes from '../constant/orderTrack';
import * as req from '../request/orderTrack';

// /**
//  * 获取轨迹详情
//  */
// export function searchSaleBillByOrderNoForRN(params) {
//     return async dispatch => {
//         const res = await req.searchSaleBillByOrderNoForRN(params);
//         if (res) {
//             dispatch({ type: actionTypes.ORDER_TRACK_PROFILE, payload: res })
//         }
//     }
// }
// /**
//  * 退款
//  */
// export function searchMergeProcessedOrdersByOrderNoForRN(params) {
//     return async dispatch => {
//         const res = await req.searchMergeProcessedOrdersByOrderNoForRN(params);
//         if (res) {
//             dispatch({ type: actionTypes.ORDER_TRACK_PROFILE, payload: res })
//         }
//     }
// }
// /**
//  * 码单
//  */
// export function searchMemoTrack(params) {
//     return async dispatch => {
//         const res = await req.searchMemoTrack(params);
//         if (res) {
//             dispatch({ type: actionTypes.ORDER_TRACK_MEMO, payload: res })
//         }
//     }
// }

/**
 * 获取订单轨迹信息
 */
export function getCxmOrderTrackVo(params) {
    return async dispatch => {
        const res = await req.getCxmOrderTrackVo(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_TRACK_PROFILE, payload: res })
        }
    }
}
/**
 * 码单
 */
export function getMemoTrack(params) {
    return async dispatch => {
        const res = await req.getMemoTrack(params);
        if (res) {
            dispatch({ type: actionTypes.ORDER_TRACK_MEMO, payload: res })
        }
    }
}
import React from 'react';
import numeral from 'numeral';
import crypto from 'crypto';
// import dynamic from 'umi/dynamic';
import { isEmptyObject } from '../utils/reg';
import { getAuthorityUserInfo, getStallInfo } from '../utils/authority';

// const numeral = dynamic(require('numeral'));

export function commEncode(str, source = 'utf8', cry = 'base64') {
    const encoder = new Buffer(str, source).toString(cry);
    return encoder;
}

export function SHA1(sin) {
    const hash = crypto.createHash('sha1');
    hash.update(sin);
    return hash.digest('hex');
}

export function BASE64_encode(str) {
    return commEncode(str, 'utf8', 'base64');
}

export function BASE64_decode(str) {
    return commEncode(str, 'base64', 'utf8');
}

export function genaralPassword(password) {
    password = password + 'eason';
    password = SHA1(password);
    return password.toLowerCase();
}

const TOKEN = 'CaiXM';
export function generalSignature(timestamp, nonce, method) {
    let userinfo = getAuthorityUserInfo();
    let hash = !isEmptyObject(userinfo) ? userinfo.accessToken : '';
    var arr = new Array(5);
    arr[0] = TOKEN;
    arr[1] = timestamp;
    arr[2] = nonce;
    arr[3] = method;
    arr[4] = hash;
    arr.sort();
    let temp = arr.join('');
    return SHA1(temp);
}

export function generalParames(method) {
    //console.log(1, method)
    let timestamp = new Date().getTime();
    let nonce = 'CaiXM';
    let userinfo = getAuthorityUserInfo();
    // console.log(2, userinfo)
    let hash = !isEmptyObject(userinfo) ? userinfo.accessToken : '';
    let signature = generalSignature(timestamp, nonce, method);

    let stall = getStallInfo();
    let stallid = !isEmptyObject(stall) ? stall.id : 0;

    if (typeof androidToJs !== 'undefined') {
        const token = window.androidToJs.getToken();
        if (token) {
            localStorage.setItem('androidToken', token);
        }
        return { time: timestamp, nonce: nonce, method: method, signature: signature, accessToken: token, stallsId: stallid, isjs: 'js', appType: 'android', pageNo: 0, pageSize: 20 }
    }

    return { time: timestamp, nonce: nonce, method: method, signature: signature, accessToken: hash, stallsId: stallid, isjs: 'js', appType: 'web', pageNo: 0, pageSize: 20 };
}

export function generalParamsWithP(url, avrg) {
    var addition = generalParames(url);
    //console.log(3, addition)
    let stallid = addition.stallsId;

    // 获取加解密消息
    let params = { stallId: stallid };
    let avrgstr = '';

    if (!isEmptyObject(avrg)) {
        avrgstr = JSON.stringify({ ...avrg });
    } else {
        avrgstr = JSON.stringify({});
    }

    if (avrgstr.length > 0) {
        params = { p: BASE64_encode(avrgstr) };
    }

    if (!isEmptyObject(params)) {
        params = { ...params, ...addition };
    } else {
        params = addition;
    }
    //console.log(4, params)
    return params;
}

export function mathFormat(val, decnum = 0) {
    let integer = null;
    if (Number.isInteger(val)) {
        integer = numeral(val).format('0,0');
    } else {
        val = Number(val);
        if (val > 0) {
            integer = (val.toFixed(0) > val) ? numeral(val - 1).format('0,0') : numeral(val).format('0,0');
        } else if (val === 0) {
            integer = numeral(val).format('0,0');
        } else {
            integer = (val.toFixed(0) > val) ? numeral(val).format('0,0') : numeral(val + 1).format('0,0');
        }
    }


    if (decnum > 0) {
        let po = Math.pow(10, decnum);
        let format = '';
        for (let i = 0; i < decnum; i++) {
            format += '0';
        }
        const decimal = numeral(Math.round(val * po) % po).format(format);
        if (decimal.length === decnum) {
            return { integer: integer, decimal: decimal }
        }
        return { integer: integer, decimal: Math.abs(decimal) }
    } else {
        return { integer: integer }
    }
}

export function Yuan(amount, decnum = 2, numExtraWrap) {
    console.log(amount);
    
    if (amount != null) {
        if (decnum === 0) {
            return (<span className={numExtraWrap}><span>{numeral(amount).format('0,0')}</span><small></small></span>);
        } else {
            let { integer, decimal } = mathFormat(amount, decnum);
            return (<span className={numExtraWrap}><span>{integer}</span>.<small>{decimal}</small></span>);
        }
    }else{
        return '';
    }

}
// import fetch from 'dva/fetch';
import axios from 'axios';
import qs from 'qs';
import CryptoJS from 'crypto-js';  //引用AES源码js
import { getJAVAApiHost } from '../utils/urls';
import { generalParamsWithP } from '../utils/math';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export default async function request(options) {
  try {
    let headers = {}, data = '';
    if (options.headers === 'json') {
      headers = {
        'content-type': 'application/json;charset=UTF-8',
        appType: 'CXMCOMSERVICEAPI',
        xsign: signForParams(options.params, 'POST'),//'f99dddc1190a6beacbf7cd74be46ffa5',
      };
      data = options.params;
    } else {
      headers = { 'content-type': 'application/x-www-form-urlencoded' }
      let params = generalParamsWithP(options.url, options.params);
      data = qs.stringify(params);
    }
    const axiosParams = {
      url: options.url,
      method: options.method || 'POST',
      baseURL: options.baseURL || getJAVAApiHost(),
      headers,
      data,
    }
    // debugger
    let response = await axios(axiosParams);
    response = checkStatus(response);
    // console.log(response);     
    return response.data;
  } catch (error) {
    console.error('request', error);
  }
}

const cusSignStr = 'caixm_520';
function signForParams(params, method) {
  let sortStr = cusSignStr;
  if (method === 'POST' || method === 'PUT') {
    sortStr = sortParams(params) + cusSignStr;
  }
  let base64str = BASE64(sortStr);
  let md5Str = CryptoJS.MD5(base64str).toString();
  return md5Str;
}

function BASE64(sin) {
  let str = CryptoJS.enc.Utf8.parse(sin);
  let base64 = CryptoJS.enc.Base64.stringify(str);
  return base64;
}

function sortParams(params) {
  let keys = Object.keys(params);
  let sortArr = keys.sort();
  let paramsUrl = '';
  sortArr.forEach((item, index) => {
    const isLast = index === sortArr.length - 1;//是否是最后一个
    if (typeof params[item] === 'string') {
      paramsUrl += `${item}=${params[item]}${isLast === false ? '&' : ''}`;
    } else {
      let pStr = JSON.stringify(params[item]);
      if (pStr) {
        pStr = pStr.replace(/"/g, '');
        pStr = pStr.replace(/:/g, '=');
        pStr = pStr.replace(/,/g, ', ');
        paramsUrl += `${item}=${pStr}${isLast === false ? '&' : ''}`;
      }

    }


    // if (index !== sortArr.length - 1) {
    //   debugger
    //   if (typeof params[item] === 'string') {
    //     paramsUrl += `${item}=${params[item]}&`;
    //   } else {
    //     let pStr = JSON.stringify(params[item]);
    //     pStr = pStr.replace(/"/g, '');
    //     pStr = pStr.replace(/:/g, '=');
    //     pStr = pStr.replace(/,/g, ', ');
    //     paramsUrl += `${item}=${pStr}&`;
    //   }
    // } else {
    //   if (typeof params[item] === 'string') {
    //     paramsUrl += `${item}=${params[item]}`;
    //   } else {
    //     let pStr = JSON.stringify(params[item]);
    //     pStr = pStr.replace(/"/g, '');
    //     pStr = pStr.replace(/:/g, '=');
    //     pStr = pStr.replace(/,/g, ', ');
    //     paramsUrl += `${item}=${pStr}`;
    //   }
    // }
  });
  return paramsUrl;
}

// function createHeader(params, method) {
//   const { versionCode } = global.appBaseConfigInfo;
//   let accessToken = global.accessToken;
//   let xsign =
//     method == HttpMethodType.UPLOAD
//       ? signForParams({}, method)
//       : signForParams(params, method);
//   let contentType =
//     method == HttpMethodType.UPLOAD
//       ? 'multipart/form-data;charset=utf-8'
//       : 'application/json;charset=UTF-8';
//   let headers = {
//     ...this.headers,
//     'Content-Type': contentType,
//     xsign,
//     versionCode,
//     accessToken,
//     flush: this.flush ? 1 : 0,
//   };
//   return headers;
// }


import request from '../../utils/request';

// 获取订单详情
// export function searchSaleBillByOrderNoForRN(params) {
//     return request({ url: 'order/searchSaleBillByOrderNoForRN', params });
// }
// // 全部退款
// export function searchMergeProcessedOrdersByOrderNoForRN(params) {
//     return request({ url: 'order/searchMergeProcessedOrdersByOrderNoForRN', params });
// }
// // 码单一生
// export function searchMemoTrack(params) {
//     return request({ url: 'order/memoTrack', params });
// }

// 获取订单轨迹信息
export function getCxmOrderTrackVo(params) {
    return request({ url: 'order/getCxmOrderTrackVo', params, headers: 'json' });
}
// 码单一生
export function getMemoTrack(params) {
    return request({ url: 'order/memoTrack', params, headers: 'json' });
}

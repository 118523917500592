import React from 'react';
import { withRouter } from 'react-router-dom';

// import Home from './container/home/index';
import OrderTrack from './container/order-track/index';

import './App.less';

@withRouter
class App extends React.Component {
  render() {
    // console.log('App', window.location);//http://localhost:3000/?from=groupmessage&isappinstalled=0#/cml/h5/shareAccount?sn=WnNTcUwxd044ZkFIdzFDL0VYc2p1YVVrcEk4THg1aDlkbzVPN3AxWks0QXdFanhvVGdxM2FQYlFPVGlOcndaQWltVkFYbXpqamhBdm9WemYva09DaHpWTzAvdkRXNkhKZVBURjRTbVZ6Uk0rSEc0MWh0TlpHampUMGJSMmhNMHJaK2FldEMrdGE4Rkp4bUNHVC9LRHRTN2dUdklrL2xyRC85MkI2WkJSb3JFWmZ5R042bWFtb2RXSmwxd2EvSS81MnJ4dVNyRFk1S2pIbTJWallPMFhhNlZld1hnSS9VL1JMdjF0QzJXTys1WT0%3D
    // 获取lastIndexOf('?')和前面的'/'之间的值，这个值就是路由
    // const href = window.location.href;
    // const lastIndex = href.lastIndexOf('?');
    // const temp = lastIndex === -1 ? href : href.substring(0, lastIndex);//得到这个值：?from=singlemessage&isappinstalled=0/order
    // const path = temp.substring(temp.lastIndexOf('/') + 1) || '';//得到这个值：order
    //console.log('path', path)
    return <div className='App'>
       <OrderTrack />
      {/* {path === "index" || path === "order" || path==="batch/Profile "? <OrderTrack /> 
                                : <Home />
      } */}
    </div>
  }
}

export default App;

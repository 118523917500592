import * as actionTypes from '../constant/orderTrack';

//初始状态
const initState = {
    profile: {},//轨迹
    memoTrack: [],//码单  
}
//reducer
export function orderTrack(state = initState, action) {
    switch (action.type) {
        case actionTypes.ORDER_TRACK_PROFILE:
            return { ...state, profile: action.payload.data, };
        case actionTypes.ORDER_TRACK_MEMO:
            return { ...state, memoTrack: action.payload.data, };
        default:
            return state;
    }
}
// 销售单详情
import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Steps, Card, Row, Col, Spin, } from 'antd';
import qs from 'qs';
import { isEmptyObject } from '../../utils/reg';
// import { Yuan } from '../../utils/math';
import { fixedZeroTo4Bit } from '../../utils/utils';
import { getCxmOrderTrackVo, getMemoTrack } from '../../state/action/orderTrack'
import './style.less';

const { Step } = Steps;
const payMethodDescMap = { 1: '现金', 2: '支付宝', 3: '微信', 4: '银联', 5: '其他', 6: '扫码枪', 7: '组合支付', 8: '聚合支付', 9: '筐子抵扣', 10: '退货抵扣', 11: '远程还款', 12: '预存抵扣' };

@withRouter
@connect(
  state => state.orderTrack,
  {
    getCxmOrderTrackVo, getMemoTrack
  }
)
class OrderTrack extends PureComponent {
  state = {
    visible: false,
    buyerName: '',
    loading: true,
    showText: true,
    appType: ""
  }

  componentDidMount() {
    this.getData();//获得页面数据
  }

  //获得页面数据
  async getData(paramOrderNo) {
    this.query = qs.parse(window.location.href.substring(window.location.href.lastIndexOf('?') + 1));
    const query = this.query;
    // const { query } = this.props.location;
    // const status = query ? query.status : null;
    const orderNo = paramOrderNo ? paramOrderNo : query ? query.orderNo : null;
    // const accessToken = query ? query.accessToken : null;
    const appType = query ? query.appType : null;
    const stallId = query ? query.stallId : null;
    this.appType = appType;
    const code = query ? query.code : null;
    this.code = code;//code: react,reactnative,java
    const { requestType } = query;
    if (requestType) {//新版本有这个值
      if (requestType === 'order') {//订单的一生
        await this.props.getCxmOrderTrackVo({ orderNo, stallId });
        const { profile } = this.props;
        if (profile.orderType === 7 || profile.orderType === 9) {
          await this.props.getMemoTrack({ orderNo, stallId });
        }
      } else if (requestType === 'memo') {//码单的一生
        await this.props.getMemoTrack({ orderNo, stallId });
      }
    } else {//老版本没有这个值
      await Promise.all([
        this.props.getCxmOrderTrackVo({ orderNo, stallId }),
        this.props.getMemoTrack({ orderNo, stallId }),
      ]);
    }
    this.setState({ loading: false, });
    console.log("orderNo: " + orderNo);
  }

  componentDidUpdate() {
    this.postMessage();//发送高度给web，菜小秘，档主端
  }
  //发送高度给web，菜小秘，档主端
  postMessage = () => {
    const currDivHeight = this.getCurrHeight();
    if (this.code === 'react' || this.appType === 'web') {
      window.parent.postMessage({ currDivHeight, type: '订单轨迹' }, '*');
    } else if (this.appType === 'INTEGRATIONAPP' || this.appType === 'stallPhone') {
      if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
        window.ReactNativeWebView.postMessage(currDivHeight);//新版本的react natvie这样返回高度
      } else if (window.postMessage && typeof window.postMessage === 'function') {
        window.postMessage(currDivHeight);//老版本的react native这样返回高度
      }
      // } else if (this.appType === 'stallPhone') {
      //   window.postMessage(currDivHeight);
    }
  }

  displayDiv = (id) => {
    let memo = this.refs[id].style;

    if (memo.display === 'block') {
      memo.display = 'none';
    } else {
      memo.display = 'block';
    }
  }

  getCurrHeight() {
    var currDiv = document.getElementById("currDiv");
    if (currDiv) {
      //console.log(currDiv.offsetHeight);
      return currDiv.offsetHeight;//当前页面显示高度
    }
    return document.body.offsetHeight;//网页可见区域高
  }

  showNumber(packKind, number, numberUnit = '件') {
    return number > 0 ? number + numberUnit : '-';
  }

  showWeight(packKind, weight) {
    let showWeightElement;
    if (weight > 0) {
      if (packKind === 7) {
        showWeightElement = '-'
      } else {
        showWeightElement = weight
      }
    } else {
      showWeightElement = '-'
    }
    return showWeightElement;
  }

  loadOrderDetailByOrderNo = (e, orderNo) => {
    e.preventDefault();
    try {
      this.getData(orderNo);//获得页面数据
      if (window.h5Handler) {
        window.h5Handler.loadOrderDetailByOrderNo(orderNo);
      }
    } catch (error) {
      console.log('h5Handler 没有定义');
    }
  }

  /**
   * 格式化的优惠和多收的内容
   * @param {string} discountAmount 优惠的金额
   * @param {string} overchargeAmount 多收的金额
   */
  getFormatText(discountAmount, overchargeAmount) {
    let headerHtml = "";
    if (discountAmount > 0 && overchargeAmount > 0) {
      headerHtml = <Fragment>（已优惠{discountAmount}元，多收{overchargeAmount}元）</Fragment>;
    } else if (discountAmount > 0) {
      headerHtml = <Fragment>（已优惠{discountAmount}元）</Fragment>;
    } else if (overchargeAmount > 0) {
      headerHtml = <Fragment>（已多收{overchargeAmount}元）</Fragment>;
    }
    return headerHtml;
  }

  render() {
    let { profile, memoTrack } = this.props;
    const {
      // createOrderName, 
      createTime,
      cashierName,//收银人
      updateName,//更新人，也是作废人
      orderSaleAmount, //应&emsp;&emsp;收
      // orderDiscountAmount, orderOverchargeAmount,
      cashierTime, payStatus, refundList, orderTrackList,
      cancelTime,//作废时间
      refundTime,//退款时间
      // cancelAmount,//作废金额
      refundAmount,//退款金额
      relatedOrderNo,//有值，表示重开订单（关联编号）
      changeFlag,//重开状态  0正常单  1.重开原单 2.重开新单
      changeCount = 1,//改单的次数
      enable,
    } = profile;
    // const { memoTrack } = isEmptyObject(batch) ? {} : batch;

    const orderState = isEmptyObject(profile) ? 0 : profile.orderStatus;
    const { showText } = this.state;

    // const orderAmount = orderState === 20 ? refundAmount : orderSaleAmount + orderDiscountAmount - orderOverchargeAmount;//应&emsp;&emsp;收\
    //黎叔讲这段有问题，现在有部分退款的情况，所以需要改成这样
    //黄佩说后端已经算好了，现在取外面的orderSaleAmount
    // const orderAmount = orderSaleAmount + orderDiscountAmount - orderOverchargeAmount;//应&emsp;&emsp;收\

    //修改逻辑是找到每个step的步数
    // let stepNumberMemoTrackDesc,//起始步数
    let stepNumberCreateOrderDesc = 0,//开票的步数  菜小秘APP去掉了开票这一步所以要为0
      stepNumberCreditDesc,//赊欠的步数
      stepNumberReimbursemenDesc,//还款的步数
      stepNumberCashierDesc,//
      stepNumberInvalidDesc,//作废
      stepNumberRefundDesc,//退款
      stepNumberReopenFirst = 0,//重开新单
      stepNumberReopenLast;//重开原单

    // let hasReopenLast = false;
    if (relatedOrderNo) {//重开订单
      if (changeFlag === 1) {//重开原单
        // hasReopenLast = true;
      } else if (changeFlag === 2) {//重开新单
        stepNumberReopenFirst = 0;
      }
    }
    if (memoTrack && memoTrack.length > 0) {//开票
      stepNumberCreateOrderDesc = memoTrack.length + 0 + stepNumberReopenFirst;//这里不能+1，所以改为+0
    } else {
      stepNumberCreateOrderDesc = 0 + stepNumberReopenFirst;
    }

    if (orderState === 10 && payStatus === 5) {// 开票-->收银
      if (orderTrackList) {
        const length = orderTrackList.filter(v => v.payStatus === 5).length;
        stepNumberCashierDesc = stepNumberCreateOrderDesc + length;
        stepNumberReopenLast = stepNumberCashierDesc + 1;
      } else {
        stepNumberReopenLast = stepNumberCreateOrderDesc + 1;
      }

      // StepFlow = (
      //   <Steps direction="vertical" current={0}>
      //     {cashierDesc}
      //     {/* <Step title="收银" description={cashierDesc} /> */}
      //     <Step title="开票" description={createOrderDesc} status={1} />
      //     {memoTrackDesc}
      //   </Steps>
      // )
    } else if (orderState === 1 && enable) {// 开票--》赊欠
      stepNumberCreditDesc = stepNumberCreateOrderDesc + 1;
      if (orderTrackList) {
        const length = orderTrackList.filter(v => v.payStatus === 20).length;
        stepNumberReimbursemenDesc = stepNumberCreditDesc + length;
        stepNumberReopenLast = stepNumberReimbursemenDesc + 1;
      } else {
        stepNumberReopenLast = stepNumberCreditDesc + 1;
      }
      // StepFlow = (
      //   <Steps direction="vertical" current={0}>
      //     {reimbursemenDesc}
      //     <Step title="赊欠" description={creditDesc} />
      //     <Step title="开票" description={createOrderDesc} />
      //     {memoTrackDesc}
      //   </Steps>
      // )
    } else if ((orderState === 5 || orderState === 10) && payStatus === 20) {// 开票--》赊欠--》还款
      stepNumberCreditDesc = stepNumberCreateOrderDesc + 1;
      if (orderTrackList) {
        const length = orderTrackList.filter(v => v.payStatus === 20).length;
        stepNumberReimbursemenDesc = stepNumberCreditDesc + length;
        // console.log('orderTrackList', orderTrackList, length, stepNumberCreditDesc, stepNumberReimbursemenDesc);
        stepNumberReopenLast = stepNumberReimbursemenDesc + 1;
      } else {
        stepNumberReopenLast = stepNumberCreditDesc + 1;
      }
      // StepFlow = (
      //   <Steps direction="vertical" current={0}>
      //     {reimbursemenDesc}
      //     <Step title="赊欠" description={creditDesc} icon={stepNumberCreateOrderDesc + 1} />
      //     <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} />
      //     {memoTrackDesc}

      //   </Steps>
      // )
    } else if (orderState === 1 && !enable) {// 开票--》赊欠-->作废
      stepNumberCreditDesc = stepNumberCreateOrderDesc + 1;
      if (orderTrackList) {
        const length = orderTrackList.filter(v => v.payStatus === 20).length;
        stepNumberReimbursemenDesc = stepNumberCreditDesc + length;
        stepNumberInvalidDesc = stepNumberReimbursemenDesc + 1;
      } else {
        stepNumberInvalidDesc = stepNumberCreditDesc + 1;
      }
      stepNumberReopenLast = stepNumberInvalidDesc;
    } else if (orderState === 20) {// 退款
      if (refundList && refundList.length) {// 开票--》赊欠--》还款--》退款；payStatus:15-->20-->10
        stepNumberCreditDesc = stepNumberCreateOrderDesc + 1;
        if (orderTrackList) {
          const length = orderTrackList.filter(v => v.payStatus === 20).length;
          stepNumberReimbursemenDesc = stepNumberCreditDesc + length;
          stepNumberRefundDesc = stepNumberReimbursemenDesc + 1;
        } else {
          stepNumberRefundDesc = stepNumberCreditDesc + 1;
        }
        stepNumberReopenLast = stepNumberRefundDesc;
      } else {// 开票-->收银--》退款；payStatus:5-->10
        if (orderTrackList) {
          const length = orderTrackList.filter(v => v.payStatus === 5).length;
          stepNumberCashierDesc = stepNumberCreateOrderDesc + length;
          stepNumberRefundDesc = stepNumberCashierDesc + 1;
        } else {
          stepNumberRefundDesc = stepNumberCreditDesc + 1;
        }
        stepNumberReopenLast = stepNumberRefundDesc;
      }
    } else {
      // 码单一生
      stepNumberReopenLast = stepNumberCreateOrderDesc + 1;
    }

    let reopenFirstDesc = null;//新单 作废重开或者退款重开
    let reopenLastDesc = null;//原单 作废重开或者退款重开
    let invalidDesc = null;//作废
    let refundCashierName = cashierName;
    if (orderTrackList && orderTrackList.length > 1) {
      refundCashierName = orderTrackList[orderTrackList.length - 1].createName;
    }
    let refundDesc = null;//退款
    if (relatedOrderNo) {//重开订单
      // if (enable === false) {//表示作废订单
      // } else if (enable && payStatus == 10) {//表示退款订单
      // }
      // const {
      //   createName,
      //   cashierName,
      //   // orderSaleAmount,//应&emsp;&emsp;收
      //   discountAmount,//优惠
      //   creditAmount,//下欠
      //   financeAmount,//实&emsp;&emsp;收/还款金额
      //   createTime,
      //   payMethod,
      //   payMethodAmountList
      // } = profile.reopenOrderTrack || {};
      //查看改单变量
      const seeChange = (this.appType === 'android' || this.appType === 'INTEGRATIONAPP' || true)
        && <div><a href="/" onClick={e => { this.loadOrderDetailByOrderNo(e, relatedOrderNo) }}>查看{`${changeFlag === 2 ? '改单前' : '改单后'}`} > </a></div>
      if (changeFlag === 1) {//重开原单
        reopenLastDesc = <Step
          icon={stepNumberReopenLast}
          // title={`${enable === false ? '作废' : '退款'}重开`}
          title='被改单'
          description={
            <div className={'stepDescription'}>
              <div className="title-time title-time3">{moment(enable === false ? cancelTime : (refundTime || createTime)).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div>操作人员：
                {
                  enable === false
                    ? updateName //createName ? createName : cashierName
                    : refundCashierName
                }
              </div>
              {seeChange}
            </div>
          }
        />

      } else if (changeFlag === 2) {//重开新单
        // const { enable } = profile.reopenOrderTrack || {};      
        const { createTime: trackCreateTime, createName, orderSaleAmount, discountAmount, overChargeAmount, financeAmount, payMethod, payMethodAmountList, creditAmount, } = (orderTrackList && orderTrackList.length > 0) ? orderTrackList[0] : {};
        reopenFirstDesc = <Step
          icon={1}
          // title={`${enable === false ? '作废' : '退款'}重开`}
          title={`第${changeCount}次改单`}
          description={
            <div className={'stepDescription'}>
              <div className="title-time title-time5">{moment(trackCreateTime || createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div>操作人员：{createName || cashierName}</div>
              <div>应&emsp;&emsp;收：{orderSaleAmount}元{(discountAmount > 0 || overChargeAmount > 0) && this.getFormatText(discountAmount, overChargeAmount)}</div>
              {financeAmount > 0 && <div>实&emsp;&emsp;收：{financeAmount}元</div>}
              {
                financeAmount > 0 && <div>
                  支付方式：
                  {
                    payMethod === 7
                      ? <div className="paymethod-list">
                        {payMethodAmountList && payMethodAmountList.map((payItem, i) => {
                          return <span key={i}>{payMethodDescMap[payItem.payMethod]}{payItem.amount}元<br /></span>
                        })}
                      </div>
                      : <span>{payMethodDescMap[payMethod]}{financeAmount}元</span>
                  }
                </div>
              }
              {creditAmount > 0 && <div>下&emsp;&emsp;欠：<span className="creditAmount">{creditAmount}</span>元</div>}
              {seeChange}
            </div>
          }
        />
      } else {

      }
      // 作废
      invalidDesc = (
        <div className={'stepDescription'}>
          <div className="title-time title-time2">{moment(profile.cancelTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          {/* <div>操作人员：{createName ? createName : cashierName}</div> */}
          {/* <div>操作人员：{cashierName}</div> */}
          <div>操作人员：{updateName}</div>  {/* A开的单子，B作废，应该显示B */}
          {
            refundAmount > 0
              ? <Fragment>
                {/* <div>应&emsp;&emsp;收：{Yuan(orderSaleAmount, 2)}元</div> */}
                <div>退款金额：{refundAmount}元</div>
              </Fragment>
              : <Fragment>
                <div>作废金额：{orderSaleAmount}元</div>
              </Fragment>
          }
        </div>
      );
      // 全部退款
      refundDesc = (
        <div className={'stepDescription'}>
          <div className="title-time title-time2">{moment(profile.refundTime || createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div>操作人员：{refundCashierName}</div>
          {/* <div>应&emsp;&emsp;收：{Yuan(orderSaleAmount, 2)}元</div> */}
          <div>退&emsp;&emsp;款：{refundAmount}元</div>
        </div>
      );

    } else {
      // 作废
      // let createName = '';
      // if (orderTrackList) {
      //   orderTrackList.forEach((item) => {
      //     if (!item.enable) {
      //       createName = item.createName;
      //     }
      //   })
      // }
      invalidDesc = (
        <div className={'stepDescription'}>
          <div className="title-time title-time2">{moment(profile.cancelTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          {/* <div>操作人员：{createName ? createName : cashierName}</div> */}
          <div>操作人员：{updateName}</div>  {/* A开的单子，B作废，应该显示B */}
          <div>作废金额：{orderSaleAmount}元</div>
        </div>
      );
      // 全部退款
      refundDesc = (
        <div className={'stepDescription'}>
          <div className="title-time title-time2">{moment(profile.refundTime || createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div>操作人员：{refundCashierName}</div>
          {/* <div>应&emsp;&emsp;收：{Yuan(orderSaleAmount, 2)}元</div> */}
          <div>退&emsp;&emsp;款：{refundAmount}元</div>
        </div>
      );
    }
    // 开票
    // const createOrderDesc = (
    //   <div className={`textSecondary stepDescription`}>
    //     <div className="title-time title-time2">{moment(createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
    //     <div>操作人员：{createOrderName}</div>
    //     <div>应&emsp;&emsp;收：{Yuan(orderAmount, 2)}元</div>
    //   </div>
    // );
    //赊欠改为现在的逻辑在orderTrackLst里面去
    let creditDesc;
    // console.log('orderTrackList', orderTrackList);
    if (orderTrackList) {
      creditDesc = orderTrackList.map((item, index) => {
        // console.log('item', item, item.payStatus === 15);
        const { orderSaleAmount, discountAmount, overChargeAmount, creditAmount, financeAmount, payMethod, payMethodAmountList } = item;
        if (item.payStatus === 15) {//payStatus=15是赊欠          
          return <div className={'stepDescription'} key={index}>
            <div className="title-time title-time2">{moment(item.cashierTime || item.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>操作人员：{item.createName}</div>
            {/* <div>赊&emsp;&emsp;账：{Yuan(orderAmount - item.discountAmount + item.overChargeAmount, 2)} 元</div>
            {item.discountAmount ? <div>优&emsp;&emsp;惠：{Yuan(item.discountAmount, 2)}元</div> : null}
            {item.overChargeAmount ? <div>多&emsp;&emsp;收：{Yuan(item.overChargeAmount, 2)}元</div> : null} */}
            <div>应&emsp;&emsp;收：{orderSaleAmount}元{(discountAmount > 0 || overChargeAmount > 0) && this.getFormatText(discountAmount, overChargeAmount)}</div>
            {financeAmount > 0 && <div>实&emsp;&emsp;收：{financeAmount}元</div>}
            {
              financeAmount > 0 && <div>
                支付方式：
                {
                  payMethod === 7
                    ? <div className="paymethod-list">
                      {payMethodAmountList && payMethodAmountList.map((payItem, i) => {
                        return <span key={i}>{payMethodDescMap[payItem.payMethod]}{payItem.amount}元<br /></span>
                      })}
                    </div>
                    : <span>{payMethodDescMap[payMethod]}{financeAmount}元</span>
                }
              </div>
            }
            {creditAmount > 0 && <div>下&emsp;&emsp;欠：<span className="creditAmount">{creditAmount}</span>元</div>}
          </div>;
        } else {
          return null;
        }
      });
    }
    // console.log('creditDesc', creditDesc);

    // 还款
    let reimbursemenDesc;
    //收银
    let cashierDesc;
    if (orderTrackList) {
      // orderTrackList.reverse();
      const newOrderTrackLst = [];
      for (let i = orderTrackList.length - 1; i >= 0; i--) {
        newOrderTrackLst.push(orderTrackList[i]);
      }
      reimbursemenDesc = newOrderTrackLst.filter(v => v.payStatus === 20).map((item, index) => {
        // if (item.payStatus == 20) {
        const {
          orderSaleAmount,//应&emsp;&emsp;收
          discountAmount,//优惠
          overChargeAmount,//多收
          creditAmount,//下欠
          financeAmount,//实&emsp;&emsp;收/还款金额
        } = item;
        return <Step
          key={item.id}
          icon={stepNumberReimbursemenDesc - index}
          title={'还款' + (item.enable ? '' : '（被撤销）')}
          description={
            <div className={`${!item.enable && 'revoke'} stepDescription`}>
              <div className={`title-time title-time${item.enable ? '2' : '7'}`}>{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div>操作人员：{item.cashierName ? item.cashierName : item.createName}</div>
              {/* <div>还&emsp;&emsp;款：{Yuan(item.financeAmount, 2)}元</div>
              {item.discountAmount ? <div>优&emsp;&emsp;惠：{Yuan(item.discountAmount, 2)}元</div> : null}
              {item.overChargeAmount ? <div>多&emsp;&emsp;收：{Yuan(item.overChargeAmount, 2)}元</div> : null} */}
              {(discountAmount > 0 || overChargeAmount > 0) && <div>应&emsp;&emsp;收：{orderSaleAmount}元{this.getFormatText(discountAmount, overChargeAmount)}</div>}
              <div>还款金额：{financeAmount}元</div>
              <div>支付方式：
                {
                  item.payMethod === 7
                    ? <div className="paymethod-list">
                      {item.payMethodAmountList && item.payMethodAmountList.map((payItem, i) => {
                        return <span key={i}>{payMethodDescMap[payItem.payMethod]}{payItem.amount}元<br /></span>
                      })}
                    </div>
                    : <span>{payMethodDescMap[item.payMethod]}{item.financeAmount}元</span>
                }
              </div>
              {creditAmount > 0 && <div>下&emsp;&emsp;欠：<span className="creditAmount">{creditAmount}</span>元</div>}
            </div>
          }
        />
      })

      cashierDesc = newOrderTrackLst.filter(v => v.payStatus === 5).map((item, index) => {
        // if (item.payStatus == 5) {
        const {
          orderSaleAmount,//应&emsp;&emsp;收
          discountAmount,//优惠
          overChargeAmount,//多收
          creditAmount,//下欠
          financeAmount,//实&emsp;&emsp;收/还款金额
        } = item;
        return <Step
          key={item.id}
          icon={stepNumberCashierDesc - index}
          title="收银"
          description={
            <div className={'stepDescription'}>
              <div className="title-time title-time2">{moment(!cashierTime ? createTime : cashierTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div>操作人员：{cashierName}</div>
              {/* <div>收&emsp;&emsp;银：{orderState === 20 ? Yuan(refundAmount, 2) : Yuan(paymentAmount, 2)}元</div>
              {item.discountAmount ? <div>优&emsp;&emsp;惠：{Yuan(item.discountAmount, 2)}元</div> : null}
              {item.overChargeAmount ? <div>多&emsp;&emsp;收：{Yuan(item.overChargeAmount, 2)}元</div> : null} */}
              <div>应&emsp;&emsp;收：{orderSaleAmount}元{(discountAmount > 0 || overChargeAmount > 0) && this.getFormatText(discountAmount, overChargeAmount)}</div>
              <div>实&emsp;&emsp;收：{financeAmount}元</div>
              <div>
                支付方式：
                {
                  item.payMethod === 7
                    ? <div className="paymethod-list">
                      {item.payMethodAmountList && item.payMethodAmountList.map((payItem, i) => {
                        return <span key={i}>{payMethodDescMap[payItem.payMethod]}{payItem.amount}元<br /></span>
                      })}
                    </div>
                    : <span>{payMethodDescMap[item.payMethod]}{item.financeAmount}元</span>
                }
              </div>
              {creditAmount > 0 && <div>下&emsp;&emsp;欠：<span className="creditAmount">{creditAmount}</span>元</div>}
            </div>
          }
        />
        // }
      }
      )
    }

    // 码单一生
    let memoTrackDesc = [];
    if (memoTrack && memoTrack.length > 0) {
      for (let x = memoTrack.length - 1; x >= 0; x--) {
        const i = x;
        const item = memoTrack[i];
        const memoOrderVoItem = item.dkMemoOrderVo;
        const lastMemoTrack = i === (memoTrack.length - 1);//是否是最后一个开码单，true是          
        memoTrackDesc.push(<Step
          key={i}
          // icon={memoTrack.length - i}
          icon={x + 1}
          title={item.remark}
          description={
            <div>
              {/* {item.dkMemoOrderVo && item.dkMemoOrderVo.length === 1 ? <div>{item.createName}</div> : null} */}
              <div className={`title-time title-time${item.remark.length}`}>{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div>操作人员：{item.createName}</div>
              <div style={{ display: showText === true ? 'block' : 'none' }}><a href="/" onClick={e => { e.preventDefault(); this.displayDiv('memo' + i) }}>点击查看详情</a></div>
              <div style={{ display: showText === false ? 'block' : 'none' }}><a href="/" onClick={e => { e.preventDefault(); this.displayDiv('memo' + i) }}>关闭详情</a></div>
              <br />
              <div ref={'memo' + i} id={'memo' + i} style={{ textAlign: 'left', color: '#8c8c8c', display: 'none' }}>
                {/* {item.dkMemoOrderVo && item.dkMemoOrderVo.map((memoOrderVoItem, memoOrderVoIndex) => { */}
                {/* const lastMemoTrack = i == (memoTrack.length - 1);//是否是最后一个开码单，true是 */}
                {/* return <div> */}
                {lastMemoTrack ? <div style={{ color: 'black', fontWeight: 'bold', marginBottom: 5 }}>原始码单 码单号:{fixedZeroTo4Bit(memoOrderVoItem.extend5, 4)}</div> : ''}
                {/* <div style={{ color: 'black', fontWeight: 'bold', marginBottom: 5 }}>码单号:{fixedZeroTo4Bit(memoOrderVoItem.extend5, 4)} {lastMemoTrack ? `开单员:${memoOrderVoItem.createName || ''}` : null}</div> */}
                <div style={{ marginBottom: 5 }}>开单日期 &nbsp;&nbsp;{moment(memoOrderVoItem.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div style={{ marginBottom: 5 }}>销售单号 &nbsp;&nbsp;{memoOrderVoItem.orderNo}</div>
                <div style={{ marginBottom: 5 }}>购货方&nbsp;&nbsp; {memoOrderVoItem.buyerName} &nbsp;&nbsp;手机号&nbsp;&nbsp; {memoOrderVoItem.buyerPhone ? memoOrderVoItem.buyerPhone : '-'}</div>
                {item.memoRemarkList && item.memoRemarkList instanceof Array && item.memoRemarkList.length > 0 ? <div dangerouslySetInnerHTML={{ __html: `备注&nbsp;&nbsp; ${item.memoRemarkList.join('<br />')}` }}></div> : null}
                <br />
                {/* <hr style={{ backgroundColor: '#eee', borderWidth: 0.3 }} /> */}

                {memoOrderVoItem.orderItemLst.map((orderItem, index) => {
                  return (
                    <div key={index} style={{ borderTopColor: '#eee', borderTopStyle: 'solid', borderTopWidth: 0.3 }}>
                      <div style={{ justifyContent: 'space-between' }}>
                        <Row>
                          <Col span={16}>
                            <font style={{ color: 'black', fontWeight: 'bold' }}>{index + 1}.{orderItem.goodsAttrName}</font>
                            {/* 如果是自营的商行，那么批次号就为0，应该不显示 */}
                            {orderItem.batchCarNo === 0 ? '' : `${orderItem.supplierName}${fixedZeroTo4Bit(orderItem.batchCarNo, 4)}`}
                            &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;
                          </Col>
                          <Col span={8}>
                            小计 <font style={{ color: 'black', fontWeight: 'bold' }}>{orderItem.saleAmount}</font>元
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>数量：{this.showNumber(orderItem.packKind, orderItem.saleNumber, orderItem.numberUnit)}</Col>
                          <Col span={8}>重量：{this.showWeight(orderItem.packKind, orderItem.saleWeight)}</Col>
                          <Col span={8}>单价：{orderItem.unitPrice}{orderItem.extend3}</Col>
                        </Row>
                        <Row>
                          <Col span={8}>货款：{orderItem.goodsAmount}元</Col>
                        </Row>
                        <br />
                      </div>
                    </div>
                  )
                })}
                <div style={{ color: 'black', }}>应收 {memoOrderVoItem.orderSaleAmount > 0 ? memoOrderVoItem.orderSaleAmount + '元' : <font style={{ color: '#fff', backgroundColor: '#F56C6C' }}> &nbsp; 信息不完善 &nbsp;</font>}</div>
                <br />
                {/* </div>
                })} */}
              </div>
            </div>
          }
        />
        );
      }
    }

    let StepFlow;
    if (orderState === 10 && payStatus === 5) {// 开票-->收银
      StepFlow = (
        <Steps direction="vertical" current={0}>
          {reopenLastDesc}
          {reopenFirstDesc == null && cashierDesc}
          {/* <Step title="收银" description={cashierDesc} /> */}
          {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
          {memoTrackDesc}
          {reopenFirstDesc}
        </Steps>
      )
    } else if (orderState === 1 && enable) {// 开票--》赊欠
      StepFlow = (
        <Steps direction="vertical" current={0}>
          {reopenLastDesc}
          {reimbursemenDesc}
          {reopenFirstDesc == null && <Step title="赊欠" description={creditDesc} icon={stepNumberCreditDesc} />}
          {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
          {memoTrackDesc}
          {reopenFirstDesc}
        </Steps>
      )
    } else if ((orderState === 5 || orderState === 10) && payStatus === 20) {// 开票--》赊欠--》还款
      StepFlow = (
        <Steps direction="vertical" current={0}>
          {reopenLastDesc}
          {reimbursemenDesc}
          {reopenFirstDesc == null && <Step title="赊欠" description={creditDesc} icon={stepNumberCreditDesc} />}
          {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
          {memoTrackDesc}
          {reopenFirstDesc}
        </Steps>
      )
    } else if (orderState === 1 && !enable) {// 开票--》赊欠-->作废   // 开票--》赊欠-->被改单
      StepFlow = (
        <Steps direction="vertical" current={0}>
          {
            //作废只能二选一
            reopenLastDesc
              ? reopenLastDesc
              : <Step title="作废" description={invalidDesc} icon={stepNumberInvalidDesc} />
          }
          {reimbursemenDesc}
          {reopenFirstDesc == null && <Step title="赊欠" description={creditDesc} icon={stepNumberCreditDesc} />}
          {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
          {memoTrackDesc}
          {reopenFirstDesc}
        </Steps>
      )
    } else if (orderState === 20) {// 退款
      if (refundList && refundList.length) {// 开票--》赊欠--》还款--》作废；payStatus:15-->20-->10 开票--》赊欠--》还款--》被改单
        StepFlow = (
          <Steps direction="vertical" current={0}>
            {
              //退款只能二选一
              reopenLastDesc
                ? reopenLastDesc
                : <Step title="作废" description={refundDesc} icon={stepNumberRefundDesc} />
            }
            {reimbursemenDesc}
            {reopenFirstDesc == null && <Step title="赊欠" description={creditDesc} icon={stepNumberCreditDesc} />}
            {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
            {memoTrackDesc}
            {reopenFirstDesc}
          </Steps>
        )
      } else {// 开票-->收银--》作废；payStatus:5-->10  开票-->收银--》被改单        
        StepFlow = (
          <Steps direction="vertical" current={0}>
            {
              //退款只能二选一
              reopenLastDesc
                ? reopenLastDesc
                : <Step title="作废" description={refundDesc} icon={stepNumberRefundDesc} />
            }
            {reopenFirstDesc == null && cashierDesc}
            {/* <Step title="收银" description={cashierDesc} /> */}
            {/* <Step title="开票" description={createOrderDesc} icon={stepNumberCreateOrderDesc} /> */}
            {memoTrackDesc}
            {reopenFirstDesc}
          </Steps>
        )
      }
    } else {
      StepFlow = (
        <Steps direction="vertical" current={0}>
          {reopenLastDesc}
          {memoTrackDesc}
          {reopenFirstDesc}
        </Steps>
      )
    }

    return <div className="OrderTrack">
      <Spin spinning={this.state.loading}>
        <Card id='currDiv' title="" style={{ marginBottom: 24, color: 'black', textAlign: 'left', minHeight: 200 }} bordered={false} >
          {StepFlow}
        </Card>
      </Spin>
    </div>
      ;
  }
}

export default OrderTrack;

// 后端api地址
// let api_admin = 'https://stall.mp.caixm.cn/caixmmp-b/';

// const NODE_ENV = process.env.REACT_APP_API; //process.env.NODE_ENV;
// if (NODE_ENV === 'test') {//测试环境
//     api_admin = 'http://test.api2x.caixm.cn/caixmmp-b/';
// } else if (NODE_ENV === 'preview') {//预发布
//     api_admin = 'http://pre.api2x.caixm.cn/caixmmp-b/';
// } else if (NODE_ENV === 'production') {//正式环境
//     api_admin = 'https://stall.mp.caixm.cn/caixmmp-b/';

//     //如果是正式环境，需要从http:跳转到https://
//     var protocolStr = document.location.protocol;
//     var hostname = document.location.hostname;
//     if (protocolStr === "http:" && hostname !== "localhost") {
//         // console.log("protocol = " + protocolStr);
//         var url = window.location.href;
//         window.location.href = "https://" + url.substring(7, url.length);
//     }
// } else {//本地开发环境
//     // api_admin = 'http://dev.api.caixm.cn/caixmmp-b/';
//     // api_admin = 'https://dev.api.caixm.cn/caixmmp-b/';
//     // api_admin = 'http://test.api2x.caixm.cn/caixmmp-b/';
//     // api_admin = 'http://pre.api2x.caixm.cn/caixmmp-b/';
//     api_admin = 'https://stall.mp.caixm.cn/caixmmp-b/';
// }

// export const API_ADMIN = api_admin;//后端接口


export function getJAVAApiHost() {
	// return 'https://stall.mp.caixm.cn/caixmmp-b/' || localStorage.getItem("androidUrl") || 'https://dev.api.caixm.cn/caixmmp-b/';
	// var url = window.location.href;
	var env = getUrlParms('env');//dev 开发 	test  测试环境	portal    生产环境
	//var env = 'dev';
	if ('dev' === env) {
		// return 'http://192.168.2.111:8281/caixmmp-b/'
		// return 'http://192.168.5.123:8281/caixmmp-b/'
		// return 'http://dev.api.caixm.cn/caixmmp-b/';
		return 'http://dev.api.caixm.cn/cxm-cxmComServiceApi/';
	} else if ('test' === env) {
		// return 'http://test.api2x.caixm.cn/caixmmp-b/';
		return 'http://test.api2x.caixm.cn/cxm-cxmComServiceApi/';
	} else if ('prep' === env) {
		// return 'http://pre.api2x.caixm.cn/caixmmp-b/';
		return 'http://pre.api2x.caixm.cn/cxm-cxmComServiceApi/';
	} else if ('portal' === env) {
		// return 'https://stall.mp.caixm.cn/caixmmp-b/';
		return 'https://stall.mp.caixm.cn/cxm-cxmComServiceApi/';
	}
	// return 'https://dev.api.caixm.cn/caixmmp-b/';
	// 'http://test.api2x.caixm.cn/caixmmp-b/'
	// return 'http://test.api2x.caixm.cn/caixmmp-b/'
	// return 'https://dev.api.caixm.cn/caixmmp2/';
	// return 'http://dev.api-b.caixm.cn/caixm-b/';
	return 'http://dev.api.caixm.cn/cxm-cxmComServiceApi/';
	// return 'http://test.api2x.caixm.cn/cxm-cxmComServiceApi/'
}

//获取地址栏参数，name:参数名称
function getUrlParms(name) {
	let tstr = window.location.href;
	let index = tstr.indexOf('?')
	let str = tstr.substring(index + 1);
	let arr = str.split('&');
	let result = {};
	arr.forEach((item) => {
		let a = item.split('=');
		result[a[0]] = a[1];
	})
	return result[name];
}

(function () {
	var env = getUrlParms('env');//dev 开发 	test  测试环境	portal    生产环境
	if ('portal' === env) {
		//正式环境去掉log日志
		window.console.log = function () { };
	}
})()

import {isEmptyObject} from '../utils/reg';
import * as calmath from '../utils/math';


export function getAuthorityUserInfo() {
  let str = localStorage.getItem('cxm-user-info')
	if ( isEmptyObject( str ) ){
		return null ;
	}
  let userInfo =  JSON.parse( calmath.BASE64_decode( str ) ) ;
  return userInfo ;
}

export function setAuthority(authority) {
  if ( isEmptyObject( authority ) ){
  	localStorage.removeItem( 'cxm-stall-info' );
    localStorage.removeItem( 'cxm-user-info' );
    localStorage.removeItem( 'loginStatus' );
  	return true ;
  }
  // 存储档口信息
  localStorage.setItem('short_name', authority.stallName);

  let stallInfo = {};
  stallInfo.short_name = authority.stallName;
  stallInfo.id = authority.stallId;
  setStallInfo( stallInfo );
  let authorityStr = calmath.BASE64_encode( JSON.stringify(authority) );
  // 存储登录状态
  localStorage.setItem('loginStatus', true);
  return localStorage.setItem('cxm-user-info', authorityStr );
}

export function getStallInfo() {
  let str = localStorage.getItem('cxm-stall-info');
  if ( isEmptyObject( str ) ){
    return null ;
  }
  let stallInfo =  JSON.parse( calmath.BASE64_decode( str ) ) ;
  return stallInfo ;
}

export function setStallInfo( stallinfo ) {
  if ( isEmptyObject( stallinfo ) ){
    let userInfo = getAuthorityUserInfo();
    if ( isEmptyObject( userInfo ) ){
  	 return localStorage.removeItem('cxm-stall-info' );
    }
    stallinfo = isEmptyObject( userInfo.stalls ) ? null : userInfo.stalls[0] ;
    if ( isEmptyObject( stallinfo ) ){
      return ;
    }
  }
  localStorage.setItem('short_name', stallinfo.short_name);
  let stallinfoStr = calmath.BASE64_encode( JSON.stringify(stallinfo) );
  return localStorage.setItem('cxm-stall-info', stallinfoStr );
}

// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';

import reducers from './reducers';

import './rem'//支持rem单位
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
// const basename = '/share';
const basename = '/#';

//开发环境
// console.log( window.__REDUX_DEVTOOLS_EXTENSION__)
// const store = createStore(reducers, compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f));
const store = createStore(reducers, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
//正式环境，不让浏览器redux插件查看状态
// const store = createStore(reducers, compose(applyMiddleware(thunk), f => f));

ReactDOM.render(<Provider store={store}>
    <Router basename={basename}>
        <App></App>
    </Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export function isEmptyObject( obj ){
	if ( obj == null || obj === '' || typeof obj === 'undefined' || obj === 'undefined' ){
		return true ;
	}
	if ( Array.isArray( obj ) ){
		if ( obj.length === 0 ){
			return true; 
		}
		return false ;
	}
	for (var key in obj) {
		if(key !== undefined) return false;
	}
	return true;
}
export function trim(str) {
  return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}